var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "date-picker-content" },
    [
      _vm._t("header", function() {
        return [_c("DatePickerHeader", { attrs: { title: _vm.title } })]
      }),
      _vm._v(" "),
      _c("DatePickerNavigationHeader", {
        attrs: { title: _vm.calendarHeader },
        on: {
          "change-calendar-type": _vm.onChangeCalendarType,
          "previous-date": _vm.onPreviousDate,
          "next-date": _vm.onNextDate
        }
      }),
      _vm._v(" "),
      _c("DatePickerCalendar", {
        attrs: {
          value: _vm.value,
          "range-value": _vm.rangeValue,
          month: _vm.month,
          year: _vm.year,
          years: _vm.years,
          "calendar-type": _vm.selectedCalendarType,
          "disabled-past": _vm.disabledPast
        },
        on: {
          input: _vm.onDateChange,
          month: _vm.onMonthChange,
          year: _vm.onYearChange,
          "calendar-type": _vm.onCalendarTypeChange,
          "calendar-header": _vm.onCalendarHeaderChange
        }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }