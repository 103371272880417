var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content-header" },
    _vm._l(_vm.headers, function(header) {
      return _c("span", { key: header, staticClass: "header" }, [
        _vm._v("\n        " + _vm._s(header) + "\n    ")
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }