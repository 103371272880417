var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("InputUUIDProvider", {
    scopedSlots: _vm._u(
      [
        {
          key: "default",
          fn: function(ref) {
            var uuid = ref.uuid
            return [
              _c(
                "div",
                { class: _vm.classes },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.checkValue,
                        expression: "checkValue"
                      }
                    ],
                    ref: "checkbox",
                    attrs: {
                      id: uuid,
                      type: "checkbox",
                      disabled: _vm.disabled
                    },
                    domProps: {
                      value: _vm.label,
                      checked: Array.isArray(_vm.checkValue)
                        ? _vm._i(_vm.checkValue, _vm.label) > -1
                        : _vm.checkValue
                    },
                    on: {
                      change: function($event) {
                        var $$a = _vm.checkValue,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = _vm.label,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 && (_vm.checkValue = $$a.concat([$$v]))
                          } else {
                            $$i > -1 &&
                              (_vm.checkValue = $$a
                                .slice(0, $$i)
                                .concat($$a.slice($$i + 1)))
                          }
                        } else {
                          _vm.checkValue = $$c
                        }
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("label", { attrs: { for: uuid } }, [
                    _c(
                      "svg",
                      {
                        staticClass: "checkbox__box",
                        attrs: { viewBox: "0 0 14 10" }
                      },
                      [
                        _c("path", {
                          staticClass: "checkbox__mark",
                          attrs: { d: _vm.markDrawingCommands }
                        })
                      ]
                    ),
                    _vm._v(" "),
                    _vm.label
                      ? _c("span", {
                          staticClass: "checkbox__label",
                          domProps: { textContent: _vm._s(_vm.label) }
                        })
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _vm._t("append")
                ],
                2
              )
            ]
          }
        }
      ],
      null,
      true
    )
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }