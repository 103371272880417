var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("Select", {
    attrs: {
      value: _vm.parsedDate,
      label: _vm.label,
      type: _vm.type,
      alignment: _vm.alignment,
      size: _vm.size,
      placeholder: _vm.placeholder,
      "error-messages": _vm.errorMessages,
      hint: _vm.hint,
      required: _vm.required,
      autofocus: _vm.autofocus,
      disabled: _vm.disabled,
      "fixed-content": false,
      dismissible: false,
      clearable: ""
    },
    on: { focus: _vm.onFocus, input: _vm.onValueChange },
    scopedSlots: _vm._u(
      [
        {
          key: "prepend",
          fn: function() {
            return [_vm._t("prepend")]
          },
          proxy: true
        },
        {
          key: "append",
          fn: function() {
            return [_vm._t("append")]
          },
          proxy: true
        },
        {
          key: "dropdown",
          fn: function(ref) {
            var onSelectValue = ref.onSelectValue
            var onClear = ref.onClear
            var onApply = ref.onApply
            return [
              _c("DatePickerContent", {
                attrs: {
                  value: _vm.value,
                  format: _vm.format,
                  "disabled-past": _vm.disabledPast
                },
                on: { input: onSelectValue }
              }),
              _vm._v(" "),
              _c("SelectDropdownApplyFooter", {
                on: { clear: onClear, apply: onApply }
              })
            ]
          }
        }
      ],
      null,
      true
    )
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }