var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Select",
    _vm._g(
      _vm._b(
        {
          scopedSlots: _vm._u(
            [
              {
                key: "prepend",
                fn: function() {
                  return [_vm._t("prepend")]
                },
                proxy: true
              },
              {
                key: "value",
                fn: function() {
                  return [
                    _vm._t("value", function() {
                      return [
                        _c("span", {
                          domProps: { textContent: _vm._s(_vm.$attrs.value) }
                        })
                      ]
                    })
                  ]
                },
                proxy: true
              },
              {
                key: "append",
                fn: function() {
                  return [_vm._t("append")]
                },
                proxy: true
              },
              {
                key: "dropdown",
                fn: function(ref) {
                  var onSelectValue = ref.onSelectValue
                  var onClear = ref.onClear
                  var onApply = ref.onApply
                  return [
                    _c("ColorPickerContent", {
                      attrs: {
                        value: _vm.$attrs.value,
                        options: _vm.$attrs.options
                      },
                      on: { input: onSelectValue }
                    }),
                    _vm._v(" "),
                    _c(
                      "DropdownFooter",
                      {
                        attrs: { size: _vm.$attrs.size, "space-between": true }
                      },
                      [
                        _c("Button", {
                          attrs: { size: _vm.tinySize, title: "OK" },
                          nativeOn: {
                            click: function($event) {
                              return onApply.apply(null, arguments)
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c("Button", {
                          attrs: {
                            size: _vm.tinySize,
                            title: _vm.$t("@Core._.buttons.clear"),
                            theme: _vm.secondaryTheme
                          },
                          nativeOn: {
                            click: function($event) {
                              return onClear.apply(null, arguments)
                            }
                          }
                        })
                      ],
                      1
                    )
                  ]
                }
              }
            ],
            null,
            true
          )
        },
        "Select",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }