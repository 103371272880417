var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "color-picker-content",
      style: _vm.colorPickerContentTemplateStyle
    },
    _vm._l(_vm.options, function(color) {
      return _c("div", {
        key: color,
        class: ["color", { "color--selected": color === _vm.value }],
        style: { backgroundColor: color },
        on: {
          click: function($event) {
            return _vm.onSelect(color)
          }
        }
      })
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }