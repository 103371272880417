var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "date-picker-header" },
    [
      _vm._t("previous", function() {
        return [
          _c("Fab", {
            attrs: { theme: _vm.secondaryTheme },
            nativeOn: {
              click: function($event) {
                return _vm.onPrevious.apply(null, arguments)
              }
            },
            scopedSlots: _vm._u([
              {
                key: "icon",
                fn: function(ref) {
                  var color = ref.color
                  return [
                    _c("IconArrowSingle", {
                      attrs: { "fill-color": color, state: _vm.leftArrow }
                    })
                  ]
                }
              }
            ])
          })
        ]
      }),
      _vm._v(" "),
      _c("span", {
        staticClass: "date-picker-header__title",
        domProps: { textContent: _vm._s(_vm.title) },
        on: { click: _vm.onClick }
      }),
      _vm._v(" "),
      _vm._t("next", function() {
        return [
          _c("Fab", {
            attrs: { theme: _vm.secondaryTheme },
            nativeOn: {
              click: function($event) {
                return _vm.onNext.apply(null, arguments)
              }
            },
            scopedSlots: _vm._u([
              {
                key: "icon",
                fn: function(ref) {
                  var color = ref.color
                  return [
                    _c("IconArrowSingle", {
                      attrs: { "fill-color": color, state: _vm.rightArrow }
                    })
                  ]
                }
              }
            ])
          })
        ]
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }