var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    _vm.nodesComponent.component,
    _vm._g(
      _vm._b(
        { tag: "Component" },
        "Component",
        _vm.nodesComponent.props,
        false
      ),
      _vm.nodesComponent.listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }